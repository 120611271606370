export function getRouteName(route, prefix) {
  return `${prefix}${route}`;
}

export function getRouteWithParams(route, productId, prefix) {
  return {
    name: getRouteName(route, prefix),
    params: { product_id: productId },
  };
}
